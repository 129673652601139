<template>
    <a-row>
      <a-col :sm="24" :xs="24">
        <a-row class="mt-1" style="padding-right: 8px;" gutter="8">
          <a-col
            :lg="3"
            :md="6"
            :xs="20"
          >
            <a-select
              v-model:value="pensionsStore.data.region_id"
              style="width: 100%; height: 45px;"
              :placeholder="$t('search_pensions.asset_region')"
              class="native-select modal-select"
              :loading="pensionsStore.loadingRegions"
              @change="regionTypeChanged"
              show-search
              allowClear
              :options="regionsList"
              :filter-option="filterOption"
            ></a-select>
          </a-col>
          <a-col
            :lg="3"
            :md="6"
            :xs="20"
          >
            <a-select
              v-model:value="pensionsStore.data.pension_type"
              style="width: 100%; height: 45px;"
              :placeholder="$t('search_pensions.pension_type')"
              class="native-select modal-select"
              :loading="pensionsStore.loadingTypes"
              @change="regionTypeChanged"
              show-search
              allowClear
              :options="typeList"
              :filter-option="filterOption"
            ></a-select>
          </a-col>
          <a-col :span="8" :md="10" :xs="20">
            <a-select
              mode="multiple"
              v-model:value="pensions"
              style="width: 100%; height: 45px !important;"
              :placeholder="
                pensionsStore.loadingPensions
                  ? $t('generic.buttons.loading')
                  : (pensionsStore.data.region_id) &&
                    (pensionsStore.data.pension_type)
                  ? $t('search_pensions.asset_dropdown_placeholder')
                  : $t('search_pensions.asset_region_category')
              "
              class="native-select modal-select"
              :class="{ 'h-100': pensions.length }"
              :loading="pensionsStore.loadingPensions"
              :max-tag-count="1"
            >
              <a-select-option
                v-for="item in pensionsStore.pensionSelectedList"
                :value="item.isin_tkr_id"
                :key="item.isin_tkr_id + 'm'"
                :title="`${item.isin_tkr} | ${item.name}`"
                >{{ item.isin_tkr }} | {{ item.name }}</a-select-option
              >
            </a-select>
          </a-col>
          <a-col
            :xs="24"
            class="mt-2"
          >
            <sdButton
              type="secondary"
              :disabled="!pensions.length"
              :loading="isLoading"
              @click="handleOk"
              style="width: 10rem;"
            >
              {{ $t("generic.buttons.add_to_basket") }}
            </sdButton>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
</template>
<script setup>
import { usePensionsStore } from "@/stores/PensionsStore";
import { useBasketStore } from "@/stores/BasketStore";
import { ref, onMounted, computed } from "vue";
import { message } from "ant-design-vue";
import { useI18n } from "vue-i18n";

const pensionsStore = usePensionsStore();
const basketStore = useBasketStore();
const { t } = useI18n();
const pensions = ref([]);
const isLoading = ref(false);

const regionsList = computed(() =>
    pensionsStore.regions.map((region) => ({
      value: region.id,
      label: t(`regions.${region.cat_name}`, region.cat_name),
    }))
);

const typeList = computed(() => {
    return pensionsStore.types.map((type) => ({
      value: type.fund_type,
      label: t(`pension_categories.${type.fund_type}`, type.fund_type),
    }));
});

const handleOk = async () => {
    isLoading.value = true;
    try {
      const result = await pensionsStore.getPensionsByTkr(pensions.value);
      if (result && Array.isArray(result)) {
        await basketStore.addAsset(result);
        message.success(t("funds_printer.added_pensions"));
      }
    } catch (err) {
      console.log(err);
    } finally {
      isLoading.value = false;
    }
    pensions.value = [];
  };

const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const regionTypeChanged = async () => {
    if ( pensionsStore.data.region_id && pensionsStore.data.pension_type) {
      try {
        await pensionsStore.searchPensions();
      } catch (err) {
        console.log(err);
      }
    }
};

onMounted(async () => {
    pensionsStore.getRegions();
    pensionsStore.getPensionTypes();
});
</script>
<style scoped lang="scss">
:deep(.native-select) {
    border: 1px #000 solid;
    padding: 0 !important;
    .ant-select-selector {
      border: none !important;
      height: 100% !important;
    }
    &.ant-select-open {
      .ant-select-selector {
        border: none !important;
        height: 100% !important;
      }
    }
    & .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
}
:deep() {
    .aling-self-center {
      align-self: center;
    }
}
</style>